<template>
    <div class="shadow-md overflow-hidden border-t border-r border-gray-200 rounded-tr-lg rounded-br-lg">
        <router-link :to="`/tutorials/${tutorial.slug}`" class="flex flex-col h-full relative">
            <div class="absolute left-0 top-0 bottom-0 w-1 bg-orange-gradient"></div>
            <div
                v-if="size === 'normal'"
                class="flex flex-row justify-center items-center w-full py-2">
                <img :src="tutorial.artwork" class="h-32" />
            </div>
            <div
                class="w-full h-full px-6 flex flex-col"
                :class="{
                    'pt-4 pb-8': size === 'normal',
                    'pt-2 pb-6': size === 'compact',
                }">
                <div>
                    <h2
                        class="block mb-3 font-semibold"
                        :class="{
                            'text-xl': size === 'normal',
                            'text-lg': size === 'compact',
                        }">{{ tutorial.title }}</h2>
                    <p class="tutorial-desc text-base mb-6">{{ tutorial.description }}</p>
                </div>
                <div class="flex flex-row justify-end items-center text-sm text-gray-700 mb-6 md:mb-0 mt-auto">
                    <span class="mr-3">{{ `${lessons.length} Lesson${lessons.length > 1 ? 's' : ''}` }}</span>
                    <span class="text-sm flex flex-row items-center mr-3">
                        <timeSvg class="h-4 mr-1 fill-current" />{{ estimatedCompletionTime }}
                    </span>
                    <rewardSvg
                        v-if="completed"
                        class="completion-badge h-5"
                    />
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import mixin from './mixin';

export default {
    name: 'TutorialItem',

    mixins: [mixin],

    props: {
        tutorial: {
            type: Object,
            required: true,
        },

        size: {
            type: String,
            default: 'normal',
        },
    },

    computed: {
        lessons() {
            const runtime = this.tutorial.runtimes[0].name;
            const lessons = this.tutorial.lessons
                .filter((l) => l.runtime === runtime);
            return lessons;
        },

        completed() {
            const totalLessons = this.lessons.length;
            const { userData } = this.tutorial;
            if (userData === undefined) return false;
            return userData.stat.completed.length === totalLessons;
        },

        estimatedCompletionTime() {
            const courseWords = this.lessons.reduce((base, lesson) => {
                const lessonWords = lesson.content[0].content;
                return `${base} ${lessonWords}`;
            }, '');

            const estimate = this.getEstimatedCompletionTime(courseWords);
            return estimate;
        },
    },

    components: {
        timeSvg: () => import('@/assets/img/time.svg'),
        rewardSvg: () => import('@/assets/img/reward.svg'),
    },
};
</script>

<style lang="postcss" scoped>
.tutorial-desc {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: theme("colors.gray.900");
}

p {
    color: theme("colors.gray.700");
}
</style>
