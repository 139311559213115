var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow-md overflow-hidden border-t border-r border-gray-200 rounded-tr-lg rounded-br-lg"},[_c('router-link',{staticClass:"flex flex-col h-full relative",attrs:{"to":`/tutorials/${_vm.tutorial.slug}`}},[_c('div',{staticClass:"absolute left-0 top-0 bottom-0 w-1 bg-orange-gradient"}),(_vm.size === 'normal')?_c('div',{staticClass:"flex flex-row justify-center items-center w-full py-2"},[_c('img',{staticClass:"h-32",attrs:{"src":_vm.tutorial.artwork}})]):_vm._e(),_c('div',{staticClass:"w-full h-full px-6 flex flex-col",class:{
                'pt-4 pb-8': _vm.size === 'normal',
                'pt-2 pb-6': _vm.size === 'compact',
            }},[_c('div',[_c('h2',{staticClass:"block mb-3 font-semibold",class:{
                        'text-xl': _vm.size === 'normal',
                        'text-lg': _vm.size === 'compact',
                    }},[_vm._v(_vm._s(_vm.tutorial.title))]),_c('p',{staticClass:"tutorial-desc text-base mb-6"},[_vm._v(_vm._s(_vm.tutorial.description))])]),_c('div',{staticClass:"flex flex-row justify-end items-center text-sm text-gray-700 mb-6 md:mb-0 mt-auto"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(`${_vm.lessons.length} Lesson${_vm.lessons.length > 1 ? 's' : ''}`))]),_c('span',{staticClass:"text-sm flex flex-row items-center mr-3"},[_c('timeSvg',{staticClass:"h-4 mr-1 fill-current"}),_vm._v(_vm._s(_vm.estimatedCompletionTime)+" ")],1),(_vm.completed)?_c('rewardSvg',{staticClass:"completion-badge h-5"}):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }